import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import { Routes, Route, BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';


import reportWebVitals from './reportWebVitals';
import App1Page from './pages/app1/App1Page';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App1Page />}/>
  </Routes>
  </BrowserRouter>
);

reportWebVitals();
