import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    // 检测用户当前使用的语言
    // 文档: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // 注入 react-i18next 实例
    .use(initReactI18next)
    // 初始化 i18next
    // 配置参数的文档: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
        resources: {
            en: {
                translation: {
                    "AppName": "EGGX NFT Collection",
                    "Alert1": "Please put NFT ID in slot {{idx}}",
                    "Alert2": "NFT {{idx}} is not the same pattern as the first NFT",
                    "Alert3": "ID {{id}} is duplicated",
                    "Alert4": "ID {{id}} is duplicated with the existing color",
                    "Alert5": "ID {{id}} has been used in the last 3 days",
                    "Alert6": "Add failed, please check if NFT #{{id}} has been destroyed.",
                    "SubmitSuccess": "Submit success",
                    "SubmitFail": "Submit fail",

                    "TotalReward": "Total Reward",
                    "RewardIssued" : "Rewards Issued",
                    "EveryReward": "Every Reward",

                    "Note1": "Collect EGGX NFTs, collect a full set of 6 colors of the same pattern to claim EGGX rewards. If the full set is all winged, you can claim 6 times the rewards.",
                    "Note2": "Please add the EGGX NFTs collected in this wallet to the slot.",
                    "Note3": "If the wrong NFT is added, you can click the slot to change.",

                    "CheckButton": "Verify and Claim EGGX",

                    "Rule1": "1. Collect EGGX NFTs, collect a full set of 6 colors of the same pattern to claim EGGX rewards.",
                    "Rule2": "2. If the full set is all winged, you can claim 6 times the rewards.",
                    "Rule3": "3. View and record 6 NFT IDs in a Web3 wallet or blockchain browser.",
                    "Rule4": "4. Add the NFT ID to the slot, verify and pay the GAS fee, and the rewards will be sent to your wallet.",
                    "Rule5": "5. The total prize pool of the collection event is 15,000,000 EGGX, which is evenly distributed in 4 periods, and 3,750,000 EGGX is issued in each period.",
                    "Rule6": "6. The amount of each claim in the 1st/2nd/3rd/4th period is 8000/6000/4000/2000 EGGX.",
                    "Rule7": "7. After claiming the rewards, EGGX NFTs can be freely traded.",
                    "Rule8": "8. Each NFT ID can only be used once within {{days}} days.",

                    "ResultMessage1": "You will receive ",
                    "ResultMessage2": "View progress on Ethscan",
                    "ResultMessage3": "You can open the blockchain browser to view the transaction",

                    "BoardError1": "NFT ID does not exist",
                    "BoardError2": "NFT ID {{id}} does not belong to the current wallet address",
                    "BoardError3": "NFT ID {{id}} has been used, please use it again after the specified time",
                    "BoardError4": "NFT ID {{id}} verification failed: ",
                    "BoardError5": "Please input NFT ID",

                    "BoardError6": "Can only be used once within {{tDays}} days. Please wait {{days}} days {{hours}} hours {{minutes}} minutes to use NFT #{{id}} again.",

                    "RuleTitle": "EGGX Collection Rules"


                }
            },
            id: {
                // 印尼语翻译
                translation: {
                   
                    "AppName": "Koleksi EGGX NFT",
                    "Alert1": "Masukkan NFT ID ke slot {{idx}}",
                    "Alert2": "NFT {{idx}} tidak memiliki pola yang sama dengan NFT pertama",
                    "Alert3": "ID {{id}} sudah ada",
                    "Alert4": "ID {{id}} sudah ada dengan warna yang ada",
                    "Alert5": "ID {{id}} sudah digunakan dalam 3 hari terakhir",
                    "Alert6": "Penambahan gagal, silakan periksa apakah NFT #{{id}} sudah dihancurkan.",

                    "SubmitSuccess": "Berhasil mengirim",
                    "SubmitFail": "Gagal mengirim",

                    "TotalReward": "Total Hadiah",
                    "RewardIssued" : "Hadiah Diterbitkan",
                    "EveryReward": "Setiap Hadiah",

                    "Note1": "Kumpulkan EGGX NFT, kumpulkan set lengkap 6 warna dengan pola yang sama untuk klaim hadiah EGGX. Jika set lengkap semuanya berwarna sayap, Anda bisa klaim 6 kali lipat hadiah.",
                    "Note2": "Tambahkan EGGX NFT yang dikumpulkan di dompet ini ke slot.",

                    "Note3": "Jika NFT yang salah ditambahkan, Anda dapat mengklik slot untuk mengganti.",
                    "CheckButton": "Verifikasi dan Klaim EGGX",

                    "Rule1": "1. Kumpulkan EGGX NFT, kumpulkan set lengkap 6 warna dengan pola yang sama untuk klaim hadiah EGGX.",
                    "Rule2": "2. Jika set lengkap semuanya berwarna sayap, Anda bisa klaim 6 kali lipat hadiah.",
                    "Rule3": "3. Lihat dan catat 6 NFT ID di dompet Web3 atau browser blockchain.",

                    "Rule4": "4. Tambahkan NFT ID ke slot, verifikasi dan bayar biaya GAS, dan hadiah akan dikirim ke dompet Anda.",
                    "Rule5": "5. Total hadiah koleksi adalah 15.000.000 EGGX, yang didistribusikan secara merata dalam 4 periode, dan 3,750,000 EGGX diterbitkan pada setiap periode.",

                    "Rule6": "6. Jumlah klaim setiap periode 1/2/3/4 adalah 8000/6000/4000/2000 EGGX.",
                    "Rule7": "7. Setelah klaim hadiah, EGGX NFT dapat diperdagangkan secara bebas.",
                    "Rule8": "8. Setiap NFT ID hanya dapat digunakan sekali dalam {{days}} hari.",
                    "ResultMessage1": "Anda akan menerima ",
                    "ResultMessage2": "Lihat progres di Ethscan",
                    "ResultMessage3": "Anda dapat membuka browser blockchain untuk melihat transaksi",
                    "BoardError1": "NFT ID tidak ada",
                    "BoardError2": "NFT ID {{id}} tidak milik alamat dompet saat ini",
                    "BoardError3": "NFT ID {{id}} sudah digunakan, gunakan lagi setelah waktu yang ditentukan",
                    "BoardError4": "Verifikasi NFT ID {{id}} gagal: ",
                    "BoardError5": "Silakan masukkan NFT ID",

                    "RuleTitle": "Aturan Koleksi EGGX",

                    "BoardError6": "Hanya dapat digunakan sekali dalam {{tDays}} hari. Harap tunggu {{days}} hari {{hours}} jam {{minutes}} menit untuk menggunakan NFT #{{id}} lagi."

                }
            },
            cn: {
                translation: {
                    "AppName": "EGGX NFT 集卡",
                    "Alert1": "请在第{{idx}}卡槽放入NFT ID",
                    "Alert2": "第{{idx}}个NFT与第一个NFT不是同一种花纹",
                    "Alert3": "ID {{id}} 重复了",
                    "Alert4": "ID {{id}} 与已有的颜色重复了",
                    "Alert5": "'ID {{id}} 在最近3天内已经使用过了",
                    "Alert6": "添加失败，请检查NFT  #{{id}} 是否已销毁。", 
                    "SubmitSuccess": "提交成功",
                    "SubmitFail": "提交失败",

                    "TotalReward": "总奖励",
                    "RewardIssued" : "已发放",
                    "EveryReward": "每次奖励",

                    "Note1": "收集EGGX NFTs，集齐同一种花纹的全套6种颜色，即可领取EGGX收益。如果全套都有翅膀，收益×6倍。",
                    "Note2": "请将这个钱包里收藏的EGGX NFTs添加到卡槽里。",
                    "Note3": "如果添加错误的NFT，可以点击卡槽更换。",
                    "CheckButton": "验证并领取EGGX",

                    "Rule1": "1、收集EGGX NFTs，集齐同一种花纹的全套6种颜色，即可领取EGGX收益。",
                    "Rule2": "2、如果全套都是有翅膀的，将可领取6倍收益。",
                    "Rule3": "3、在Web3钱包，或者区块链浏览器内查看并记录6个NFT ID。",
                    "Rule4": "4、将NFT ID添加到卡槽内，验证并支付GAS费后，收益将发送到您的钱包。",
                    "Rule5": "5、集卡活动总奖池15,000,000EGGX，平均分为4期，每期发放375万EGGX。    ",
                    "Rule6": "6、第1/2/3/4期，每次领取金额分别为8000/6000/4000/2000EGGX。",
                    "Rule7": "7、领取收益后，EGGX NFTs可以自由交易。",
                    "Rule8": "8、每个NFT ID在{{days}}天内仅可以搭配使用一次。",

                    "ResultMessage1": "您将获得 ",
                    "ResultMessage2": "在Ethscan查看进度",
                    "ResultMessage3": "可以打开区块链浏览器查看交易",

                    "BoardError1": "NFT ID不存在",
                    "BoardError2": "NFT ID {{id}} 不属于当前钱包地址",
                    "BoardError3": "NFT ID {{id}} 已经使用过了，请在规定时间后再次使用",
                    "BoardError4": "NFT ID {{id}} 验证失败: ",
                    "BoardError5": "请输入NFT ID",

                    "BoardError6": "{{tDays}}天内仅可使用一次。请等待{{days}} 天{{hours}}小时{{minutes}}分钟后再次使用NFT #{{id}}。",

                    "RuleTitle": "EGGX 集卡规则"
                }
            },
            // 韩语
            ko: {
                translation: {
                    "AppName": "EGGX NFT 컬렉션",
                    "Alert1": "NFT ID를 슬롯 {{idx}}에 넣어주세요",
                    "Alert2": "NFT {{idx}}는 첫 번째 NFT와 패턴이 다릅니다",
                    "Alert3": "ID {{id}}가 중복되었습니다",
                    "Alert4": "ID {{id}}가 기존 색상과 중복되었습니다",
                    "Alert5": "ID {{id}}가 최근 3일간 사용되었습니다",
                    "Alert6": "추가 실패, NFT #{{id}}가 이미 파괴되었는지 확인하십시오.",
                    "SubmitSuccess": "제출 성공",
                    "SubmitFail": "제출 실패",

                    "TotalReward": "총 보상",
                    "RewardIssued" : "발행된 보상",
                    "EveryReward": "매 보상",


                    "Note1": "EGGX NFT를 수집하고, 동일한 패턴의 6가지 색상을 모두 모아 EGGX 보상을 청구하세요. 모든 세트가 날개가 있는 경우 6 배의 보상을 청구할 수 있습니다.",
                    "Note2": "이 지갑에 수집한 EGGX NFT를 슬롯에 추가하세요.",
                    "Note3": "잘못된 NFT를 추가하면 슬롯을 클릭하여 변경할 수 있습니다.",
                    "CheckButton": "검증 및 EGGX 청구",

                    "Rule1": "1. EGGX NFT를 수집하고, 동일한 패턴의 6가지 색상을 모두 모아 EGGX 보상을 청구하세요.",
                    "Rule2": "2. 모든 세트가 날개가 있는 경우 6 배의 보상을 청구할 수 있습니다.",
                    "Rule3": "3. Web3 지갑이나 블록체인 브라우저에서 6개의 NFT ID를 보고 기록하세요.",
                    "Rule4": "4. NFT ID를 슬롯에 추가하고, 검증 및 GAS 수수료를 지불한 후 보상이 지갑으로 전송됩니다.",
                    "Rule5": "5. 컬렉션 이벤트의 총 상금은 15,000,000 EGGX로, 4개 기간에 고르게 분배되며, 각 기간에 3,750,000 EGGX가 발행됩니다.",
                    "Rule6": "6. 1/2/3/4 기간의 각 청구 금액은 8000/6000/4000/2000 EGGX입니다.",
                    "Rule7": "7. 보상을 청구한 후 EGGX NFT를 자유롭게 거래할 수 있습니다.",
                    "Rule8": "8. 각 NFT ID는 {{days}}일 내에 한 번만 사용할 수 있습니다.",
                    "ResultMessage1": "당신은 받을 ",
                    "ResultMessage2": "Ethscan에서 진행 상황 확인",

                    "ResultMessage3": "블록체인 브라우저에서 거래 내역을 확인할 수 있습니다",
                    "BoardError1": "NFT ID가 없습니다",
                    "BoardError2": "NFT ID {{id}}가 현재 지갑 주소에 속하지 않습니다",
                    "BoardError3": "NFT ID {{id}}가 이미 사용되었습니다. 지정된 시간 이후에 다시 사용하십시오",
                    "BoardError4": "NFT ID {{id}} 확인 실패: ",
                    "BoardError5": "NFT ID를 입력하십시오",
                    "BoardError6": "{{tDays}}일 내에 1회만 사용할 수 있습니다. {{days}}일 {{hours}}시간 {{minutes}}분 후에 NFT #{{id}}를 다시 사용하십시오.",

                    "RuleTitle": "EGGX 컬렉션 규칙"

                }

            },

            // 日语
            ja: {
                translation: {
                    "AppName": "EGGX NFT コレクション",
                    "Alert1": "スロット{{idx}}にNFT IDを入れてください",
                    "Alert2": "NFT {{idx}}は最初のNFTとパターンが異なります",
                    "Alert3": "ID {{id}}が重複しています",
                    "Alert4": "ID {{id}}が既存の色と重複しています",
                    "Alert5": "ID {{id}}は最近3日間使用されました",
                    "Alert6": "追加に失敗しました。NFT #{{id}}がすでに破壊されているかどうかを確認してください。",
                    "SubmitSuccess": "提出成功",
                    "SubmitFail": "提出失敗",

                    "TotalReward": "総報酬",
                    "RewardIssued" : "発行済み報酬",
                    "EveryReward": "毎報酬",

                    "Note1": "EGGX NFTを集め、同じパターンの6つの色を集めてEGGX報酬を請求してください。全セットがすべて羽がある場合、報酬×6倍を請求できます。",
                    "Note2": "このウォレットに収集したEGGX NFTをスロットに追加してください。",
                    "Note3": "間違ったNFTを追加した場合、スロットをクリックして変更できます。",
                    "CheckButton": "検証してEGGXを請求",

                    "Rule1": "1. EGGX NFTを集め、同じパターンの6つの色を集めてEGGX報酬を請求してください。",
                    "Rule2": "2. 全セットが羽がある場合、6倍の報酬を請求できます。",
                    "Rule3": "3. Web3ウォレットまたはブロックチェーンブラウザで6つのNFT IDを表示して記録してください。",
                    "Rule4": "4. NFT IDをスロットに追加し、検証してGAS手数料を支払った後、報酬がウォレットに送信されます。",
                    "Rule5": "5. コレクションイベントの総賞金は15,000,000 EGGXで、4つの期間に均等に分配され、各期間に3,750,000 EGGXが発行されます。",
                    "Rule6": "6. 第1/2/3/4期の各請求額はそれぞれ8000/6000/4000/2000 EGGXです。",
                    "Rule7": "7. 報酬を請求した後、EGGX NFTは自由に取引できます。",
                    "Rule8": "8. 各NFT IDは{{days}}日以内に1回だけ使用できます。",
                    "ResultMessage1": "あなたは受け取る ",
                    "ResultMessage2": "Ethscanで進捗を確認",
                    "ResultMessage3": "ブロックチェーンブラウザで取引を確認できます",
                    "BoardError1": "NFT IDがありません",
                    "BoardError2": "NFT ID {{id}}は現在のウォレットアドレスに属していません",
                    "BoardError3": "NFT ID {{id}}は既に使用されています。指定された時間後に再使用してください",
                    "BoardError4": "NFT ID {{id}}の検証に失敗しました: ",
                    "BoardError5": "NFT IDを入力してください",
                    "BoardError6": "{{tDays}}日以内に1回だけ使用できます。{{days}}日{{hours}}時間{{minutes}}分後にNFT #{{id}}を再使用してください。",

                    "RuleTitle": "EGGX コレクションルール"


                }
            },

            // 西班牙语

            es: {
                translation: {
                    "AppName": "Colección de EGGX NFT",
                    "Alert1": "Por favor, coloque el ID de NFT en la ranura {{idx}}",
                    "Alert2": "El NFT {{idx}} no es el mismo patrón que el primer NFT",
                    "Alert3": "El ID {{id}} está duplicado",
                    "Alert4": "El ID {{id}} está duplicado con el color existente",
                    "Alert5": "El ID {{id}} se ha utilizado en los últimos 3 días",
                    "Alert6": "Error al agregar, por favor verifique si el NFT #{{id}} ha sido destruido.",
                    "SubmitSuccess": "Enviar con éxito",
                    "SubmitFail": "Fracaso al enviar",

                    "TotalReward": "Recompensa total",
                    "RewardIssued" : "Recompensas emitidas",
                    "EveryReward": "Cada recompensa",

                    "Note1": "Colecciona EGGX NFTs, colecciona un conjunto completo de 6 colores del mismo patrón para reclamar recompensas EGGX. Si el conjunto completo tiene alas, puedes reclamar 6 veces las recompensas.",
                    "Note2": "Agregue los EGGX NFTs recolectados en esta billetera a la ranura.",

                    "Note3": "Si se agrega el NFT incorrecto, puede hacer clic en la ranura para cambiar.",
                    "CheckButton": "Verificar y reclamar EGGX",

                    "Rule1": "1. Colecciona EGGX NFTs, colecciona un conjunto completo de 6 colores del mismo patrón para reclamar recompensas EGGX.",
                    "Rule2": "2. Si el conjunto completo tiene alas, puedes reclamar 6 veces las recompensas.",

                    "Rule3": "3. Vea y registre 6 NFT ID en una billetera Web3 o un navegador de blockchain.",
                    "Rule4": "4. Agregue el NFT ID a la ranura, verifique y pague la tarifa de GAS, y las recompensas se enviarán a su billetera.",
                    "Rule5": "5. El premio total del evento de colección es de 15,000,000 EGGX, que se distribuye uniformemente en 4 períodos, y se emiten 3,750,000 EGGX en cada período.",
                    "Rule6": "6. La cantidad de cada reclamo en el 1er/2do/3er/4to período es de 8000/6000/4000/2000 EGGX.",
                    "Rule7": "7. Después de reclamar las recompensas, los EGGX NFT se pueden negociar libremente.",
                    "Rule8": "8. Cada NFT ID solo se puede usar una vez dentro de {{days}} días.",
                    "ResultMessage1": "Recibirá ",
                    "ResultMessage2": "Ver progreso en Ethscan",
                    "ResultMessage3": "Puede abrir el navegador de blockchain para ver la transacción",
                    "BoardError1": "El ID de NFT no existe",
                    "BoardError2": "El ID de NFT {{id}} no pertenece a la dirección de billetera actual",
                    "BoardError3": "El ID de NFT {{id}} se ha utilizado, utilícelo nuevamente después del tiempo especificado",
                    "BoardError4": "La verificación del ID de NFT {{id}} falló: ",
                    "BoardError5": "Por favor ingrese el ID de NFT",
                    "BoardError6": "Solo se puede usar una vez dentro de {{tDays}} días. Espere {{days}} días {{hours}} horas {{minutes}} minutos para usar NFT #{{id}} nuevamente.",


                    "RuleTitle": "Reglas de colección de EGGX"
                }
            },
            // 法语
            fr: {
                translation: {
                    "AppName": "Collection de EGGX NFT",
                    "Alert1": "Veuillez placer l'ID de NFT dans la fente {{idx}}",
                    "Alert2": "Le NFT {{idx}} n'est pas le même motif que le premier NFT",
                    "Alert3": "L'ID {{id}} est dupliqué",
                    "Alert4": "L'ID {{id}} est dupliqué avec la couleur existante",
                    "Alert5": "L'ID {{id}} a été utilisé au cours des 3 derniers jours",
                    "Alert6": "Échec de l'ajout, veuillez vérifier si le NFT #{{id}} a été détruit.",
                    "SubmitSuccess": "Soumettre avec succès",
                    "SubmitFail": "Échec de la soumission",

                    "TotalReward": "Récompense totale",
                    "RewardIssued" : "Récompenses émises",
                    "EveryReward": "Chaque récompense",

                    "Note1": "Collectionnez des EGGX NFT, collectez un ensemble complet de 6 couleurs du même motif pour réclamer des récompenses EGGX. Si l'ensemble complet a des ailes, vous pouvez réclamer 6 fois les récompenses.",
                    "Note2": "Veuillez ajouter les EGGX NFT collectés dans ce portefeuille à la fente.",
                    "Note3": "Si le mauvais NFT est ajouté, vous pouvez cliquer sur la fente pour changer.",
                    "CheckButton": "Vérifier et réclamer EGGX",

                    "Rule1": "1. Collectionnez des EGGX NFT, collectez un ensemble complet de 6 couleurs du même motif pour réclamer des récompenses EGGX.",
                    "Rule2": "2. Si l'ensemble complet a des ailes, vous pouvez réclamer 6 fois les récompenses.",
                    "Rule3": "3. Voir et enregistrer 6 ID NFT dans un portefeuille Web3 ou un navigateur blockchain.",
                    "Rule4": "4. Ajoutez l'ID NFT à la fente, vérifiez et payez les frais de GAS, et les récompenses seront envoyées à votre portefeuille.",
                    "Rule5": "5. Le pool de prix total de l'événement de collection est de 15 000 000 EGGX, réparti uniformément en 4 périodes, et 3,750,000 EGGX sont émis à chaque période.",
                    "Rule6": "6. Le montant de chaque réclamation dans le 1er/2ème/3ème/4ème période est de 8000/6000/4000/2000 EGGX.",
                    "Rule7": "7. Après avoir réclamé les récompenses, les EGGX NFT peuvent être librement échangés.",
                    "Rule8": "8. Chaque ID NFT ne peut être utilisé qu'une seule fois dans les {{days}} jours.",
                    "ResultMessage1": "Vous recevrez ",
                    "ResultMessage2": "Voir la progression sur Ethscan",
                    "ResultMessage3": "Vous pouvez ouvrir le navigateur blockchain pour voir la transaction",
                    "BoardError1": "L'ID NFT n'existe pas",
                    "BoardError2": "L'ID NFT {{id}} n'appartient pas à l'adresse de portefeuille actuelle",
                    "BoardError3": "L'ID NFT {{id}} a été utilisé, veuillez l'utiliser à nouveau après le temps spécifié",
                    "BoardError4": "La vérification de l'ID NFT {{id}} a échoué: ",
                    "BoardError5": "Veuillez entrer l'ID NFT",
                    "BoardError6": "Ne peut être utilisé qu'une seule fois dans les {{tDays}} jours. Veuillez attendre {{days}} jours {{hours}} heures {{minutes}} minutes pour utiliser à nouveau NFT #{{id}}.",

                    "RuleTitle": "Règles de collection EGGX"

                }
            },
            de:{
                translation: {
                    "AppName": "EGGX NFT-Sammlung",
                    "Alert1": "Bitte setzen Sie die NFT-ID in den Steckplatz {{idx}}",
                    "Alert2": "NFT {{idx}} ist nicht das gleiche Muster wie das erste NFT",
                    "Alert3": "ID {{id}} ist dupliziert",
                    "Alert4": "ID {{id}} ist mit der vorhandenen Farbe dupliziert",
                    "Alert5": "ID {{id}} wurde in den letzten 3 Tagen verwendet",
                    "Alert6": "Hinzufügen fehlgeschlagen, bitte überprüfen Sie, ob das NFT #{{id}} zerstört wurde.",
                    "SubmitSuccess": "Erfolgreich einreichen",
                    "SubmitFail": "Einreichen fehlgeschlagen",

                    "TotalReward": "Gesamtbetrag",
                    "RewardIssued" : "Ausgegebene Belohnungen",
                    "EveryReward": "Jede Belohnung",

                    "Note1": "Sammeln Sie EGGX NFTs, sammeln Sie einen kompletten Satz von 6 Farben des gleichen Musters, um EGGX-Belohnungen zu beanspruchen. Wenn der vollständige Satz alle Flügel hat, können Sie 6-mal die Belohnungen beanspruchen.",
                    "Note2": "Bitte fügen Sie die in dieser Brieftasche gesammelten EGGX NFTs dem Steckplatz hinzu.",
                    "Note3": "Wenn das falsche NFT hinzugefügt wird, können Sie auf den Steckplatz klicken, um zu ändern.",
                    "CheckButton": "Überprüfen und EGGX beanspruchen",

                    "Rule1": "1. Sammeln Sie EGGX NFTs, sammeln Sie einen kompletten Satz von 6 Farben des gleichen Musters, um EGGX-Belohnungen zu beanspruchen.",
                    "Rule2": "2. Wenn der vollständige Satz alle Flügel hat, können Sie 6-mal die Belohnungen beanspruchen.",
                    "Rule3": "3. Sehen und notieren Sie 6 NFT-IDs in einer Web3-Brieftasche oder einem Blockchain-Browser.",
                    "Rule4": "4. Fügen Sie die NFT-ID dem Steckplatz hinzu, überprüfen und zahlen Sie die GAS-Gebühr, und die Belohnungen werden an Ihre Brieftasche gesendet.",
                    "Rule5": "5. Der Gesamtpreispool des Sammelereignisses beträgt 15.000.000 EGGX, der gleichmäßig in 4 Perioden verteilt ist, und in jeder Periode werden 3,750,000 EGGX ausgegeben.",
                    "Rule6": "6. Die Höhe jeder Forderung in der 1./2./3./4. Periode beträgt 8000/6000/4000/2000 EGGX.",
                    "Rule7": "7. Nachdem Sie die Belohnungen beansprucht haben, können EGGX NFTs frei gehandelt werden.",
                    "Rule8": "8. Jede NFT-ID kann innerhalb von {{days}} Tagen nur einmal verwendet werden.",
                    "ResultMessage1": "Sie erhalten ",
                    "ResultMessage2": "Fortschritt auf Ethscan anzeigen",
                    "ResultMessage3": "Sie können den Blockchain-Browser öffnen, um die Transaktion anzuzeigen",
                    "BoardError1": "NFT-ID existiert nicht",
                    "BoardError2": "NFT-ID {{id}} gehört nicht zur aktuellen Brieftaschenadresse",
                    "BoardError3": "NFT-ID {{id}} wurde bereits verwendet, verwenden Sie sie nach der angegebenen Zeit erneut",
                    "BoardError4": "NFT-ID {{id}} Überprüfung fehlgeschlagen: ",
                    "BoardError5": "Bitte geben Sie die NFT-ID ein",
                    "BoardError6": "Kann innerhalb von {{tDays}} Tagen nur einmal verwendet werden. Bitte warten Sie {{days}} Tage {{hours}} Stunden {{minutes}} Minuten, um NFT #{{id}} erneut zu verwenden.",

                    "RuleTitle": "EGGX-Sammelregeln"

                }
            }
        },
        
    });
export default i18n;