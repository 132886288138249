export const RPC = "https://mainnet.infura.io/v3/"
export const CHAIN_ID = 1
export const CHAIN_NAME = "ETH"
export const CHAIN_SYMBOL = "ETH"
export const CHAIN_BLOCK_TIME = 15
export const CHAIN_EXPLORER = "https://etherscan.io"
export const EGGX_CONTRACT = "0xe2f95ee8B72fFed59bC4D2F35b1d19b909A6e6b3"
export const EGGX_APP1_CONTRACT = "0xFb35DE57B117FA770761C1A344784075745F84F9"



// holesky
// export const RPC = "https://ethereum-holesky.publicnode.com"
// export const CHAIN_ID = 17000
// export const CHAIN_NAME = "Holesky"
// export const CHAIN_SYMBOL = "ETH"
// export const CHAIN_BLOCK_TIME = 15
// export const CHAIN_EXPLORER = "https://holesky.etherscan.io"
// export const EGGX_CONTRACT = "0x554b190869Fc6f257064328fbaf3C6581A96c892"
// export const EGGX_APP1_CONTRACT = "0x5a1d5c08C3e16284F699b8bC39f069dB69CA3A4a"
